#nav-bar.light {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.88);
}
#nav-bar.dark {
  background-color: #161616;
  color: rgba(255, 255, 255, 0.75);
}
#nav-bar {
  position: absolute;
  top: 0;
  z-index: 10000;
  width: 100%;
}
.content-wrapper {
  margin-top: 40px;
  height: 100vh;
  overflow: scroll;
}
.content-wrapper.coming-soon {
  overflow: hidden;
}
.content-wrapper.dark {
  background-color: #161616;
}
#nav-bar .logo h2 {
  /*color: #4c5eff;*/
  color: #33b4a7;
}
#nav-bar .logo h2:hover {
  /*color: #3f4ed4 !important;*/
  color: #2b9f94 !important;
}
#nav-bar .logo {
  cursor: pointer;
}
#nav-bar .logo:hover {
  /*color: #3f4ed4 !important;*/
  opacity: 0.8;
}
#nav-bar .ant-btn.btn-primary {
  background-color: #33b4a7;
  color: #fff !important;
}
#nav-bar.dark .ant-btn.btn-primary {
  background-color: #33b4a7;
  color: #1c1c1c !important;
}
#nav-bar .ant-btn.add-new {
  margin-left: 0;
  margin-right: 0;
}
#nav-bar .ant-btn.btn-primary:hover {
  background-color: #2b9f94 !important;
}
.btn-right {
  float: right;
  margin: 17px 16px 17px 16px;
  width: 62px;
}
.ant-drawer {
  margin-top: 64px;
}

/* -- SEARCH BAR -- */
#nav-bar .ant-input-group-wrapper {
  display: block;
}
#nav-bar .search-bar {
  border-radius: 8px;
  border: 1px solid #f5f5f5;
  background-color: transparent;
}
#nav-bar.dark .search-bar {
  border-radius: 8px;
  border: 1px solid rgb(37, 37, 37);
  background-color: transparent;
}
#nav-bar .search-bar.ant-input-affix-wrapper .ant-input {
  color: #2b9f94 !important;
}
#nav-bar .search-bar .ant-input-affix-wrapper:focus {
  border-color: #2b9f94;
  box-shadow: 0 0 0 2px rgb(43 159 148 / 10%);
  border-inline-end-width: 1px;
  outline: 0;
}
#nav-bar.dark .search-bar .ant-input-affix-wrapper:focus {
  background-color: #000000 !important;
}
#nav-bar .search-bar .search-icon {
  color: #2b9f94;
}
#nav-bar .ant-input-affix-wrapper .ant-input {
  background-color: transparent;
}
#nav-bar .ant-input-group-addon .ant-input-search-button {
  color: #2b9f94;
  background-color: transparent;
  box-shadow: none;
}
#nav-bar .ant-input-group-addon .ant-input-search-button:not(:disabled):hover {
  color: #fff;
  background-color: #2b9f94;
}
