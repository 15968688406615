#listBanner {
  background: #000000;
  width: 100%;
  padding: 0;
  margin: 0 auto;
}
#listBanner .wrapper {
  position: relative;
}

.c-portrait {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-mask {
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  -ms-transition: -ms-transform 0.5s;
  transition: transform 0.5s;
}

.c-portrait-one {
  background-image: url('https://i.imgur.com/DDloP4R.jpg');
  background-size: auto;
  clip-path: url(#clipping-one);
  border: 1px solid red;
  height: 200px;
}

.c-portrait-two {
  background-image: url('https://i.imgur.com/SnNH7nq.png');
  background-size: auto;
  clip-path: url(#clipping-two);
  border: 1px solid green;
  height: 200px;
}

.c-portrait-three {
  background-image: url('https://i.imgur.com/SnNH7nq.png');
  background-size: cover;
  clip-path: url(#clipping-three);
  border: 1px solid blue;
  height: 200px;
}

#listBanner {
  background-color: #000000;
  overflow: hidden;
  border-radius: 0;
  height: 350px;
  border: 0;
}

#listBanner .bg-wrapper {
  background-image: url('https://i.imgur.com/4IR2GZF.png');
  background-position: top;
  background-size: contain;
  background-repeat-x: repeat;
  filter: blur(0.5px);
  width: 100%;
  height: 100%;
  z-index: 200;
}
#listBanner .bg-wrapper:hover {
  filter: blur(0px) !important;
}
#listBanner .ant-card-body {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 400;
}
#listBanner .wrapper {
  text-align: center;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 600;
}
#listBanner h1 {
  position: absolute;
  top: 40%;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  color: #fff;
  z-index: 1000;
}
