.add-item-card.ant-card .ant-card-body {
  padding: 0;
}
.add-item-card.ant-card .ant-card-head {
  border-bottom: 1px solid #f0f0f0 !important;
}

.select-item-type {
  width: 165px;
  height: 100px;
  border: 0 solid #ccc;
  text-align: center;
  background-color: #fff;
  margin-top: 1px;
}
.select-item-type:hover {
  color: #fff;
  background-color: #33b4a7;
  cursor: pointer;
}
.select-item-type span {
  position: absolute;
  bottom: 10px;
  margin: auto;
  left: 0;
  right: 0;
}
.select-item-type .item-type-icon {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 10px;
  margin: auto;
  left: 0;
  right: 0;
}
.select-item-type .item-type-icon .anticon {
  height: 1em;
}

.btn-wrapper .btn-secondary {
  background-color: #eeeeee;
  color: #a2a2a2 !important;
}
.add-new {
  margin: 17px 16px 17px 16px;
  color: #2b9f94;
}
.add-new:hover,
.add-new:focus {
  color: #39cbbd !important;
}
.ant-dropdown,
.ant-popover {
  z-index: 12000;
}
.ant-dropdown .ant-dropdown-menu {
  margin-top: 6px;
}
#body.dark .ant-dropdown .ant-dropdown-menu {
  background-color: #1c1c1c;
}
#body.dark .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content,
#body.dark .ant-dropdown .ant-dropdown-menu .anticon,
#body.dark .ant-dropdown .ant-list .ant-list-item.ant-dropdown-menu-item {
  color: #cccccc !important;
}
.ant-dropdown-menu-item:hover,
.ant-list-items .ant-dropdown-menu-item:hover {
  background-color: #2b9f94 !important;
  color: #f5f5f5 !important;
}
.ant-dropdown .ant-list-header .ant-dropdown-menu-item {
  color: #1c1c1c !important;
}
.ant-dropdown .ant-list-header .ant-btn {
  width: 25px !important;
  height: 25px !important;
  margin: 2px 10px 2px 2px !important;
  color: #1c1c1c;
}
#body.dark .ant-dropdown .ant-list-header .ant-btn .anticon {
  color: #1c1c1c !important;
}
.ant-dropdown .ant-list-header .ant-dropdown-menu-item:hover,
#body.dark .ant-dropdown .ant-list-header .ant-dropdown-menu-item:hover {
  background-color: transparent !important;
  color: #2b9f94 !important;
}

.ant-dropdown-menu-item:hover .main-link {
  color: #f5f5f5 !important;
}
.ant-btn-default {
  background-color: #33b4a7 !important;
  border: 0 solid #ccc !important;
  color: #eee !important;
}
.ant-btn-default:hover {
  background-color: #2b9f94 !important;
  border: 0 solid #ccc !important;
  color: #f5f5f5 !important;
}
.main-link {
  color: #33b4a7 !important;
}
.main-link:hover {
  color: #f5f5f5 !important;
}
