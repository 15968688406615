.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.nav-sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
}
.nav-wrapper {
  float: left;
  width: 120px;
  height: 31px;
  margin: 6px 24px 16px 0;
  background: rgba(255, 255, 255, 0.2);
}

body {
  overflow: hidden;
}

.lightPrimary {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.88);
}
.darkPrimary {
  background-color: #001529;
  color: rgba(255, 255, 255, 0.75);
}
.lightSecondary {
  background-color: #aaa;
  color: #282828;
}
.darkSecondary {
  background-color: #282828;
  color: #aaa;
}
/*-------------------*/
.ant-layout .ant-layout-header {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.88);
}
.ant-layout .ant-layout-header.dark {
  background-color: #181818;
  color: rgba(255, 255, 255, 0.75);
}

.logo h1 {
  color: #4c5eff;
}
.logo h1:hover {
  color: #3f4ed4 !important;
}
.ant-btn-default {
  background-color: #4c5eff;
  border: 0 solid #ccc !important;
  color: #eee;
}
button.ant-btn-default:hover,
button.ant-btn-default:focus {
  background-color: #3f4ed4;
  color: #f5f5f5;
}
.ant-layout,
.ant-layout-footer {
  background-color: transparent !important;
  color: #161616;
}
.content-wrapper.dark .ant-layout,
.content-wrapper.dark .ant-layout-footer,
.content-wrapper.dark .ant-layout .ant-breadcrumb,
.content-wrapper.dark .ant-layout .ant-breadcrumb .ant-breadcrumb-link,
.content-wrapper.dark .ant-layout .ant-breadcrumb .ant-breadcrumb-separator {
  color: #f5f5f5;
}
